var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"536"},model:{value:(_vm.model),callback:function ($$v) {_vm.model=$$v},expression:"model"}},[(_vm.editDialogItem)?_c('v-card',{staticClass:"default-dialog px-0"},[_c('v-btn',{staticStyle:{"top":"32px","right":"32px","z-index":"10"},attrs:{"icon":"","large":"","absolute":""},on:{"click":function($event){_vm.currentTab === 'main' ? _vm.model = false : _vm.currentTab = 'main'}}},[_c('v-icon',{attrs:{"size":"32"}},[_vm._v(" mdi-close ")])],1),_c('v-toolbar',{attrs:{"flat":"","color":"transparent"}},[_c('v-toolbar-title',{style:({
          paddingBottom: _vm.$store.getters.isMobile ? 24 : 0
        })},[_vm._v(_vm._s(_vm.currentTab === 'main' ? 'Edit' : 'Edit icon'))]),_c('v-spacer')],1),_c('v-card-text',{staticClass:"px-0"},[_c('div'),_c('v-tabs-items',{staticClass:"custom-scroll",staticStyle:{"background-color":"transparent","overflow-x":"hidden"},style:(_vm.stylesForTabs),model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},[_c('v-tab-item',{attrs:{"value":"main"}},[_c('div',{staticClass:"default-padding-block"},[_c('v-subheader',{staticClass:"pl-0 default-dialog__label"},[_vm._v(" Title ")]),_c('v-text-field',{attrs:{"solo":"","filled":"","placeholder":_vm.$t('dialogs.documents.edit.input-title'),"autofocus":""},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('v-menu',{attrs:{"elevation":0,"content-class":"contextMenu","max-width":233,"min-width":233},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-card',_vm._g(_vm._b({staticClass:"pa-0",style:({
                        backgroundSize: '95px',
                        backgroundImage: !_vm.tempColor ? 'url(/img/transparent.jpg)' : null
                      }),attrs:{"width":24,"height":24,"color":_vm.tempColor}},'v-card',attrs,false),on))]}}],null,false,232693334),model:{value:(_vm.showSwatches),callback:function ($$v) {_vm.showSwatches=$$v},expression:"showSwatches"}},[_c('v-swatches',{staticClass:"folder-swatches",attrs:{"inline":"","swatches":_vm.foldersColors},model:{value:(_vm.tempColor),callback:function ($$v) {_vm.tempColor=$$v},expression:"tempColor"}})],1)]},proxy:true}],null,false,2354450875),model:{value:(_vm.tempName),callback:function ($$v) {_vm.tempName=$$v},expression:"tempName"}}),_c('div',{staticStyle:{"position":"relative"}},[_c('div',{staticStyle:{"position":"absolute","width":"100%","height":"100%","left":"0px","top":"0px","cursor":"pointer","z-index":"1"},on:{"click":_vm.onClick}}),_c('v-text-field',{attrs:{"solo":"","hide-details":"","filled":"","value":_vm.$i18n.locale === 'ru' ? 'Иконка' : 'Icon'},on:{"click":function($event){$event.stopPropagation();return _vm.onClick.apply(null, arguments)}},scopedSlots:_vm._u([{key:"append",fn:function(){return [_c('ionic-icon',{style:({
                      color: _vm.tempIcon && _vm.tempIcon.color ? _vm.tempIcon.color : 'var(--main-dark-color)'
                    }),attrs:{"icon":_vm.tempIcon,"size":"24px"}})]},proxy:true}],null,false,672607314)})],1)],1)]),_c('v-tab-item',{attrs:{"value":"icons"}},[_c('div',{staticClass:"icons-block d-flex flex-column"},_vm._l((_vm.listsIcons),function(list){return _c('div',{key:("list-icons-" + (list.name)),staticClass:"icons-block__list d-flex flex-wrap"},_vm._l((list.groups),function(group){return _c('v-row',{key:("icon-gropu-" + (group.name)),staticClass:"icons-block__group mb-3",attrs:{"no-gutters":""}},_vm._l((group.icons),function(icon){return _c('v-col',{key:("icon-" + (icon.name)),staticClass:"icons-block__item d-flex justify-center align-center",attrs:{"cols":_vm.$store.getters.isMobile ? 2 : 1}},[_c('v-btn',{staticClass:"icons-block__btn mt-1",attrs:{"icon":"","input-value":_vm.tempIcon.name === icon.name,"text":""},on:{"click":function () { return _vm.onClickIcon(Object.assign({}, icon,
                      {color: _vm.tempIcon.color})); }}},[_c('ionic-icon',{style:({
                        color: _vm.tempIcon && _vm.tempIcon.color ? _vm.tempIcon.color : 'var(--main-dark-color)'
                      }),attrs:{"size":"24px","icon":icon}})],1)],1)}),1)}),1)}),0)])],1)],1),(_vm.showColors)?[_c('v-divider',{staticClass:"mb-2"}),_c('div',{staticClass:"default-padding-block"},[_c('div',{staticClass:"icons-block__colors"},[(_vm.tempIcon)?_c('v-slide-group',{attrs:{"show-arrows":"","mandatory":""},model:{value:(_vm.tempIcon.color),callback:function ($$v) {_vm.$set(_vm.tempIcon, "color", $$v)},expression:"tempIcon.color"}},_vm._l((_vm.iconsColors),function(color,index){return _c('v-slide-item',{key:("color-" + (color.value) + "-" + index),staticStyle:{"border-radius":"100%"},style:({
                border: ("2px solid " + (color.borderColor) + " !important")
              }),attrs:{"value":color.value},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var active = ref.active;
              var toggle = ref.toggle;
return [_c('v-card',{staticClass:"icons-block__color pa-0 ma-2 d-flex justify-center align-center",staticStyle:{"border-radius":"100%","background-size":"100px"},style:(Object.assign({}, _vm.getColorStyles(color))),attrs:{"flat":"","color":color.value,"height":"24","width":"24","ripple":false},on:{"click":toggle}},[(active)?_c('v-icon',{staticClass:"mx-auto",attrs:{"color":!color.value ? 'var(--main-dark-color)' : '#fff',"size":"16"}},[_vm._v(" mdi-check ")]):_vm._e()],1)]}}],null,true)})}),1):_vm._e()],1)])]:_vm._e(),_c('v-card-actions',{staticClass:"pt-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.currentTab === 'main' ? _vm.model = false : _vm.currentTab = 'main'}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","loading":!_vm.updated,"color":"primary"},on:{"click":function($event){return _vm.$emit('success', {
          id: _vm.editDialogItem.docId,
          original: _vm.editDialogItem,
          data: {
            icon: _vm.tempIcon,
            title: _vm.tempName,
            color: _vm.tempColor
          }
        })}}},[_vm._v(" Save ")])],1)],2):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }