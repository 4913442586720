<template>
  <v-dialog
    v-model="model"
    max-width="536"
  >
    <v-card
      v-if="editDialogItem"
      class="default-dialog px-0"
    >
      <v-btn
        icon
        @click="currentTab === 'main' ? model = false : currentTab = 'main'"
        large
        absolute
        style="top: 32px; right: 32px; z-index: 10;"
      >
        <v-icon
          size="32"
        >
          mdi-close
        </v-icon>
      </v-btn>
      <v-toolbar
        flat
        color="transparent"
      >
        <v-toolbar-title
          :style="{
            paddingBottom: $store.getters.isMobile ? 24 : 0
          }"
        >{{ currentTab === 'main' ? 'Edit' : 'Edit icon' }}</v-toolbar-title>
        <v-spacer />
      </v-toolbar>
      <v-card-text class="px-0">
        <div
        
        ></div>
        <v-tabs-items
          class="custom-scroll"
          style="background-color:transparent; overflow-x: hidden;"
          :style="stylesForTabs"
          v-model="currentTab"
        >
          <v-tab-item value="main">
            <div class="default-padding-block">
              <v-subheader class="pl-0 default-dialog__label">
                Title
              </v-subheader>
              <v-text-field 
                solo
                filled
                v-model="tempName"
                :placeholder="$t('dialogs.documents.edit.input-title')"
                autofocus
              >
                <template v-slot:append>
                  <v-menu
                    :elevation="0"
                    content-class="contextMenu"
                    :max-width="233"
                    :min-width="233"
                    v-model="showSwatches"
                  >
                    <template
                      v-slot:activator="{on, attrs}"
                    >
                      <v-card
                        v-bind="attrs"
                        v-on="on"
                        class="pa-0"
                        :width="24"
                        :height="24"
                        :color="tempColor"
                        :style="{
                          backgroundSize: '95px',
                          backgroundImage: !tempColor ? 'url(/img/transparent.jpg)' : null
                        }"
                      />
                    </template>
                    <v-swatches
                      inline
                      class="folder-swatches"
                      v-model="tempColor"
                      :swatches="foldersColors"
                    />
                  </v-menu>
                </template>
              </v-text-field>
              <div
                style="position: relative;"
              >
                <div @click="onClick" style="position: absolute; width: 100%; height: 100%; left: 0px; top: 0px; cursor: pointer; z-index: 1;"></div>
                <v-text-field 
                  solo
                  hide-details
                  filled
                  :value="$i18n.locale === 'ru' ? 'Иконка' : 'Icon'"
                  @click.stop="onClick"
                >
                  <template v-slot:append>
                    <ionic-icon 
                      :icon="tempIcon"
                      size="24px"
                      :style="{
                        color: tempIcon && tempIcon.color ? tempIcon.color : 'var(--main-dark-color)'
                      }"
                    />
                  </template>
                </v-text-field>
              </div>
            </div>
          </v-tab-item>
          <v-tab-item value="icons">
            <div
              class="icons-block d-flex flex-column"
            >
              <div
                v-for="list in listsIcons"
                :key="`list-icons-${list.name}`"
                class="icons-block__list d-flex flex-wrap"
              >
                <v-row
                  v-for="group in list.groups"
                  class="icons-block__group mb-3"
                  no-gutters
                  :key="`icon-gropu-${group.name}`"
                >
                  <v-col
                    class="icons-block__item d-flex justify-center align-center"
                    v-for="icon in group.icons"
                    :cols="$store.getters.isMobile ? 2 : 1"
                    :key="`icon-${icon.name}`"
                  >
                    <v-btn
                      class="icons-block__btn mt-1"
                      icon
                      :input-value="tempIcon.name === icon.name"
                      text
                      @click="() => onClickIcon({
                        ...icon,
                        color: tempIcon.color
                      })"
                    >
                      <ionic-icon
                        size="24px"
                        :icon="icon"
                        :style="{
                          color: tempIcon && tempIcon.color ? tempIcon.color : 'var(--main-dark-color)'
                        }"
                      />
                    </v-btn>
                  </v-col>
                </v-row>
              </div>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card-text>
      <template
        v-if="showColors"
      >
        <v-divider class="mb-2"/>
        <div
          class="default-padding-block"
        >
          <div
            class="icons-block__colors"
          >
            <v-slide-group
              v-if="tempIcon"
              show-arrows
              v-model="tempIcon.color"
              mandatory
            >
              <v-slide-item
                v-for="(color, index) in iconsColors"
                :key="`color-${color.value}-${index}`"
                :value="color.value"
                v-slot="{ active, toggle }"
                style="border-radius: 100%;"
                :style="{
                  border: `2px solid ${color.borderColor} !important`
                }"
              >
                <v-card
                  flat
                  :color="color.value"
                  class="icons-block__color pa-0 ma-2 d-flex justify-center align-center"
                  height="24"
                  width="24"
                  :ripple="false"
                  :style="{
                    ...getColorStyles(color)
                  }"
                  style="border-radius: 100%; background-size: 100px;"
                  @click="toggle"
                >
                  <v-icon
                    :color="!color.value ? 'var(--main-dark-color)' : '#fff'"
                    v-if="active"
                    class="mx-auto"
                    size="16"
                  >
                    mdi-check
                  </v-icon>
                </v-card>
              </v-slide-item>
            </v-slide-group>
          </div>
        </div>
      </template>
      <v-card-actions class="pt-5">
        <v-spacer />
        <v-btn
          text
          @click="currentTab === 'main' ? model = false : currentTab = 'main'"
        >
          Cancel
        </v-btn>
        <v-btn
          text
          :loading="!updated"
          color="primary"
          @click="$emit('success', {
            id: editDialogItem.docId,
            original: editDialogItem,
            data: {
              icon: tempIcon,
              title: tempName,
              color: tempColor
            }
          })"
        >
          Save
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import IonicIcon from '@/components/ionic-icons/icon'

import VSwatches from 'vue-swatches'
import 'vue-swatches/dist/vue-swatches.css'

export default {
  props: {
    updated: {
      type: Boolean,
      default: () => true
    },
    editDialogType: {
      type: String,
      required: true
    },
    editDialogItem: {
      required: true
    },
    value: {
      type: Boolean,
      required: true
    }
  },
  name: 'EditFolderDialog',
  components: {
    IonicIcon,
    VSwatches
  },
  data: () => ({
    showSwatches: false,
    currentTab: 'main',
    tempName: '',
    tempIcon: null,
    tempColor: '',
    showColors: false
  }),
  created() {
    this.tempName = this.editDialogItem.info.title
    this.tempIcon = this.currentEditableFolderIcon
    this.tempColor = this.editDialogItem.info && this.editDialogItem.info.color || ''
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    colors() {
      return [
        null,
        '#1FBC9C', 
        '#1CA085', 
        '#2ECC70', 
        '#27AF60',
        '#3398DB',
        '#2980B9',
        '#A463BF',
        '#8E43AD',
        '#3D556E',
        '#222F3D',
        '#F2C511',
        '#F39C19',
        '#E84B3C',
        '#C0382B',
        '#E91E63',
        '#880E4F'
      ]
    },
    foldersColors() {
      return [...this.colors.filter(color => color), '']
    },
    iconsColors() {
      return this.colors.map(value => {
        const val = -0.25
        return {
          value: !value ? null : value,
          borderColor: this.pSBC(val, !value ? '#ddd' : value)
        }
      })
    },
    stylesForTabs() {
      const minHeight = this.currentTab === 'main' ? '220px' : '50vh'
      const maxHeight = this.currentTab === 'main' ? '220px' : '50vh'
      return {
        minHeight,
        maxHeight,
        overflowY: 'auto',
        transition: 'min-height 0.3s'
      }
    },
    listsIcons() {
      const lists = [
        {
          name: 'ionic',
          groups: [
            {
              name: 'group-1',
              icons: [
                {
                  name: 'folder-outline',
                  type: 'ionic'
                },
                {
                  name: 'folder-open-outline',
                  type: 'ionic'
                },
                {
                  name: 'file-tray-outline',
                  type: 'ionic'
                },
                {
                  name: 'file-tray-full-outline',
                  type: 'ionic'
                },
                {
                  name: 'file-tray-stacked-outline',
                  type: 'ionic'
                },
                {
                  name: 'archive-outline',
                  type: 'ionic'
                },
                {
                  name: 'server-outline',
                  type: 'ionic'
                },
                {
                  name: 'desktop-outline',
                  type: 'ionic'
                },
                {
                  name: 'phone-portrait-outline',
                  type: 'ionic'
                },
                {
                  name: 'cloud-outline',
                  type: 'ionic'
                },
                {
                  name: 'document-outline',
                  type: 'ionic'
                },
                {
                  name: 'document-text-outline',
                  type: 'ionic'
                },
                {
                  name: 'document-lock-outline',
                  type: 'ionic'
                },
                {
                  name: 'document-attach-outline',
                  type: 'ionic'
                },
                {
                  name: 'reader-outline',
                  type: 'ionic'
                },
                {
                  name: 'albums-outline',
                  type: 'ionic'
                },
                {
                  name: 'pencil-outline',
                  type: 'ionic'
                },
                {
                  name: 'attach-outline',
                  type: 'ionic'
                },
                {
                  name: 'pricetag-outline',
                  type: 'ionic'
                },
                {
                  name: 'pricetags-outline',
                  type: 'ionic'
                },
                {
                  name: 'flag-outline',
                  type: 'ionic'
                },
                {
                  name: 'bulb-outline',
                  type: 'ionic'
                },
                {
                  name: 'bug-outline',
                  type: 'ionic'
                },
                {
                  name: 'school-outline',
                  type: 'ionic'
                },
                {
                  name: 'game-controller-outline',
                  type: 'ionic'
                },
                {
                  name: 'print-outline',
                  type: 'ionic'
                },
                {
                  name: 'color-palette-outline',
                  type: 'ionic'
                },
                {
                  name: 'mic-outline',
                  type: 'ionic'
                },
                {
                  name: 'musical-notes-outline',
                  type: 'ionic'
                },      
                {
                  name: 'briefcase-outline',
                  type: 'ionic'
                },
                {
                  name: 'bag-outline',
                  type: 'ionic'
                },
                {
                  name: 'home-outline',
                  type: 'ionic'
                },
                {
                  name: 'business-outline',
                  type: 'ionic'
                },
                {
                  name: 'podium-outline',
                  type: 'ionic'
                },
                {
                  name: 'shield-checkmark-outline',
                  type: 'ionic'
                },
                {
                  name: 'warning-outline',
                  type: 'ionic'
                }
              ]
            },
            {
              name: 'group-2',
              icons: [
                {
                  name: 'library-outline',
                  type: 'ionic'
                },
                {
                  name: 'book-outline',
                  type: 'ionic'
                },
                {
                  name: 'bookmarks-outline',
                  type: 'ionic'
                },
                {
                  name: 'calendar-clear-outline',
                  type: 'ionic'
                },
                {
                  name: 'calendar-number-outline',
                  type: 'ionic'
                },
                {
                  name: 'calendar-outline',
                  type: 'ionic'
                },
                {
                  name: 'star-outline',
                  type: 'ionic'
                },
                {
                  name: 'star-half-outline',
                  type: 'ionic'
                },
                {
                  name: 'lock-closed-outline',
                  type: 'ionic'
                },
                {
                  name: 'lock-open-outline',
                  type: 'ionic'
                },
                {
                  name: 'chatbubble-ellipses-outline',
                  type: 'ionic'
                },
                {
                  name: 'chatbubbles-outline',
                  type: 'ionic'
                },
                {
                  name: 'mail-outline',
                  type: 'ionic'
                },
                {
                  name: 'location-outline',
                  type: 'ionic'
                },
                {
                  name: 'map-outline',
                  type: 'ionic'
                },
                {
                  name: 'image-outline',
                  type: 'ionic'
                },
                {
                  name: 'images-outline',
                  type: 'ionic'
                },
                {
                  name: 'videocam-outline',
                  type: 'ionic'
                },
                {
                  name: 'film-outline',
                  type: 'ionic'
                },
                {
                  name: 'headset-outline',
                  type: 'ionic'
                },
                {
                  name: 'trash-outline',
                  type: 'ionic'
                },
                {
                  name: 'information-circle-outline',
                  type: 'ionic'
                },
                {
                  name: 'help-circle-outline',
                  type: 'ionic'
                },
                {
                  name: 'alert-circle-outline',
                  type: 'ionic'
                },
              ]
            },
            {
              name: 'group-3',
              icons: [
                {
                  name: 'logo-wordpress',
                  type: 'ionic'
                },
                {
                  name: 'logo-medium',
                  type: 'ionic'
                },
                {
                  name: 'logo-facebook',
                  type: 'ionic'
                },
                {
                  name: 'logo-twitter',
                  type: 'ionic'
                },
                {
                  name: 'logo-buffer',
                  type: 'ionic'
                },
                {
                  name: 'logo-behance',
                  type: 'ionic'
                },
                {
                  name: 'logo-tiktok',
                  type: 'ionic'
                },
                {
                  name: 'logo-instagram',
                  type: 'ionic'
                },
                {
                  name: 'logo-tumblr',
                  type: 'ionic'
                },
                {
                  name: 'logo-youtube',
                  type: 'ionic'
                },
                {
                  name: 'logo-dribbble',
                  type: 'ionic'
                },
                {
                  name: 'logo-vimeo',
                  type: 'ionic'
                },
                {
                  name: 'logo-vk',
                  type: 'ionic'
                },
                {
                  name: 'logo-linkedin',
                  type: 'ionic'
                },
                {
                  name: 'logo-dropbox',
                  type: 'ionic'
                },
                {
                  name: 'logo-markdown',
                  type: 'ionic'
                },
                {
                  name: 'logo-foursquare',
                  type: 'ionic'
                },
                {
                  name: 'logo-snapchat',
                  type: 'ionic'
                },
                {
                  name: 'logo-pinterest',
                  type: 'ionic'
                },
                {
                  name: 'logo-twitch',
                  type: 'ionic'
                },
                {
                  name: 'logo-rss',
                  type: 'ionic'
                },
              ]
            },
            {
              name: 'group-4',
              icons: [
                {
                  name: 'heart-outline',
                  type: 'ionic'
                },
                {
                  name: 'heart-dislike-outline',
                  type: 'ionic'
                },
                {
                  name: 'partly-sunny-outline',
                  type: 'ionic'
                },
                {
                  name: 'rainy-outline',
                  type: 'ionic'
                },
                {
                  name: 'checkmark-circle-outline',
                  type: 'ionic'
                },
                {
                  name: 'checkmark-done-circle-outline',
                  type: 'ionic'
                },
                {
                  name: 'close-circle-outline',
                  type: 'ionic'
                },
                {
                  name: 'add-circle-outline',
                  type: 'ionic'
                },
                {
                  name: 'remove-circle-outline',
                  type: 'ionic'
                },
                {
                  name: 'search-circle-outline',
                  type: 'ionic'
                },
                {
                  name: 'heart-circle-outline',
                  type: 'ionic'
                },
                {
                  name: 'heart-dislike-circle-outline',
                  type: 'ionic'
                },
                {
                  name: 'notifications-circle-outline',
                  type: 'ionic'
                },
                {
                  name: 'people-circle-outline',
                  type: 'ionic'
                },
                {
                  name: 'person-circle-outline',
                  type: 'ionic'
                },
                {
                  name: 'at-circle-outline',
                  type: 'ionic'
                },
                {
                  name: 'navigate-circle-outline',
                  type: 'ionic'
                },
                {
                  name: 'list-circle-outline',
                  type: 'ionic'
                },
                {
                  name: 'cloud-circle-outline',
                  type: 'ionic'
                },
              ],
            },
            {
              name: 'group-5',
              icons: [
                {
                  name: 'balloon-outline',
                  type: 'ionic'
                },
                {
                  name: 'bandage-outline',
                  type: 'ionic'
                },
                {
                  name: 'bonfire-outline',
                  type: 'ionic'
                },
                {
                  name: 'bed-outline',
                  type: 'ionic'
                },
                {
                  name: 'body-outline',
                  type: 'ionic'
                },
                {
                  name: 'bus-outline',
                  type: 'ionic'
                },
                {
                  name: 'cafe-outline',
                  type: 'ionic'
                },
                {
                  name: 'car-sport-outline',
                  type: 'ionic'
                },
                {
                  name: 'clipboard-outline',
                  type: 'ionic'
                },
                {
                  name: 'color-palette-outline',
                  type: 'ionic'
                },
                {
                  name: 'create-outline',
                  type: 'ionic'
                },
                {
                  name: 'cube-outline',
                  type: 'ionic'
                },
                {
                  name: 'diamond-outline',
                  type: 'ionic'
                },
                {
                  name: 'dice-outline',
                  type: 'ionic'
                },
                {
                  name: 'earth-outline',
                  type: 'ionic'
                },
                {
                  name: 'easel-outline',
                  type: 'ionic'
                },
                {
                  name: 'egg-outline',
                  type: 'ionic'
                },
                {
                  name: 'extension-puzzle-outline',
                  type: 'ionic'
                },
                {
                  name: 'enter-outline',
                  type: 'ionic'
                },
                {
                  name: 'exit-outline',
                  type: 'ionic'
                },
                {
                  name: 'fast-food-outline',
                  type: 'ionic'
                },
                {
                  name: 'female-outline',
                  type: 'ionic'
                },
                {
                  name: 'transgender-outline',
                  type: 'ionic'
                },
                {
                  name: 'male-outline',
                  type: 'ionic'
                },
                {
                  name: 'male-female-outline',
                  type: 'ionic'
                },
                {
                  name: 'man-outline',
                  type: 'ionic'
                },
                {
                  name: 'woman-outline',
                  type: 'ionic'
                },
                {
                  name: 'rocket-outline',
                  type: 'ionic'
                },
                {
                  name: 'skull-outline',
                  type: 'ionic'
                },
                {
                  name: 'storefront-outline',
                  type: 'ionic'
                },
                {
                  name: 'telescope-outline',
                  type: 'ionic'
                },
                {
                  name: 'trail-sign-outline',
                  type: 'ionic'
                },
                {
                  name: 'umbrella-outline',
                  type: 'ionic'
                },
                {
                  name: 'wifi-outline',
                  type: 'ionic'
                },
                {
                  name: 'today-outline',
                  type: 'ionic'
                },
                {
                  name: 'time-outline',
                  type: 'ionic'
                },
                {
                  name: 'language-outline',
                  type: 'ionic'
                },
                {
                  name: 'leaf-outline',
                  type: 'ionic'
                },
                {
                  name: 'newspaper-outline',
                  type: 'ionic'
                },
                {
                  name: 'hourglass-outline',
                  type: 'ionic'
                },
                {
                  name: 'prism-outline',
                  type: 'ionic'
                },
                {
                  name: 'fish-outline',
                  type: 'ionic'
                },
              ]
            }
          ]
        },
        {
          name: 'emoji',
          icons: []
        }
      ]

      return lists
    },
    currentEditableFolderIcon() {
      const {info = {}} = this.editDialogItem
      const { icon } = info
      return icon ? icon : {
        name: 'folder-outline',
        type: 'ionic'
      }
    },
    tabs() {
      return [
        {
          value: 'main'
        },
        {
          value: 'icons'
        },
        {}
      ]
    },
  },
  methods: {
    getColorStyles(color) {
      const { value } = color
      const result =  {
        backgroundImage: !value ? 'url(/img/transparent.jpg)' : null
      }
      return result
    },
    pSBC(p,c0,c1,l) {
      let r,g,b,P,f,t,h,i=parseInt,m=Math.round,a=typeof(c1)=="string";
      if(typeof(p)!="number"||p<-1||p>1||typeof(c0)!="string"||(c0[0]!='r'&&c0[0]!='#')||(c1&&!a))return null;
      if(!this.pSBCr)this.pSBCr=(d)=>{
          let n=d.length,x={};
          if(n>9){
              [r,g,b,a]=d=d.split(","),n=d.length;
              if(n<3||n>4)return null;
              x.r=i(r[3]=="a"?r.slice(5):r.slice(4)),x.g=i(g),x.b=i(b),x.a=a?parseFloat(a):-1
          }else{
              if(n==8||n==6||n<4)return null;
              if(n<6)d="#"+d[1]+d[1]+d[2]+d[2]+d[3]+d[3]+(n>4?d[4]+d[4]:"");
              d=i(d.slice(1),16);
              if(n==9||n==5)x.r=d>>24&255,x.g=d>>16&255,x.b=d>>8&255,x.a=m((d&255)/0.255)/1000;
              else x.r=d>>16,x.g=d>>8&255,x.b=d&255,x.a=-1
          }return x};
      h=c0.length>9,h=a?c1.length>9?true:c1=="c"?!h:false:h,f=this.pSBCr(c0),P=p<0,t=c1&&c1!="c"?this.pSBCr(c1):P?{r:0,g:0,b:0,a:-1}:{r:255,g:255,b:255,a:-1},p=P?p*-1:p,P=1-p;
      if(!f||!t)return null;
      if(l)r=m(P*f.r+p*t.r),g=m(P*f.g+p*t.g),b=m(P*f.b+p*t.b);
      else r=m((P*f.r**2+p*t.r**2)**0.5),g=m((P*f.g**2+p*t.g**2)**0.5),b=m((P*f.b**2+p*t.b**2)**0.5);
      a=f.a,t=t.a,f=a>=0||t>=0,a=f?a<0?t:t<0?a:a*P+t*p:0;
      if(h)return"rgb"+(f?"a(":"(")+r+","+g+","+b+(f?","+m(a*1000)/1000:"")+")";
      else return"#"+(4294967296+r*16777216+g*65536+b*256+(f?m(a*255):0)).toString(16).slice(1,f?undefined:-2)
    },
    onClickIcon(icon) {
      this.tempIcon = icon
      this.currentTab = 'main'
    },
    onClick() {
      this.currentTab = 'icons'
    }
  },
  watch: {
    currentTab(to) {
      this.showColors = false
      this.$nextTick(() => {
        this.showColors = to === 'icons'
      })
    },
  }
}
</script>
<style lang="scss">
.icons-block {
  margin-right: -20px;
  height: 100%;
  position: relative;

  &__color:before {
    border-radius: 100%;
  }

  &__colors {
    position: relative;
    .v-slide-group__prev, 
    .v-slide-group__next {
      width: 52px;
      height: 24px;
      background: transparent;
      position: absolute;
      top: 50%;
      z-index: 1;
      transform: translateY(-50%);
    }

    .v-slide-group__next--disabled,
    .v-slide-group__prev--disabled {
      display: none;
    }

    .v-slide-group__prev {
      justify-content: flex-start;
      background: linear-gradient(90deg, rgba(255,255,255,1) 55%, rgba(255,255,255,0) 100%);
      left: 0px;
    }
    .v-slide-group__next {
      justify-content: flex-end;
      background: linear-gradient(270deg, rgba(255,255,255,1) 55%, rgba(255,255,255,0) 100%);
      right: 0px;
    }
  }

  &__color {
    &:before {
      border-radius: 100%;
    }
  }

  &__item {

    .v-btn:after,
    .v-btn:before {
      display: none;
    }

    .v-btn {
      border-radius: 8px;
      border: 1px solid transparent;
    }
  }

  &__item .v-btn:hover {
    border-color: inherit;
  }
}

.night .icons-block {
  &__colors {
    .v-slide-group__prev {
      background: linear-gradient(90deg, #303030 55%, rgba(255,255,255,0) 100%);
    }
    .v-slide-group__next {
      background: linear-gradient(270deg, #303030 55%, rgba(255,255,255,0) 100%);
    }
  } 
}

.folder-swatches {
  visibility: visible;
  min-height: 311px;

  .vue-swatches__container {
    height: 100%;
    padding: 0px;
    position: absolute;
    display: block;
    border-radius: 5px;
    box-shadow: 0 2px 3px rgb(10 10 10 / 20%), 0 0 0 1px rgb(10 10 10 / 20%);
    z-index: 50;
    padding: 5px;
  }

  .vue-swatches__wrapper {
    padding-left: 11px;
    width: 212px !important;
    padding-top: 11px;
    height: 301px !important;

    .vue-swatches__swatch {
      margin-right: 11px !important;
      margin-bottom: 11px !important;
    }
  }
}

.mobile .folder-swatches .vue-swatches__wrapper .vue-swatches__swatch {
  margin-top: 0px !important;
  margin-left: 0px !important;
}
</style>